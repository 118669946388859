import { toast } from "react-hot-toast";
import { firestore } from "./firebase.config";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  setDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { setOrders } from "../context/actions/orderActions";
import { setUsers } from "../context/actions/userActions";
import { LINK, ROLE } from "./constants";
import { sendOrderNotification } from "./fcm";

// GET FIRESTORE DATA - users | orders
export const getFirestoreData = async (
  collectionName,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true);
    const querySnapshot = await getDocs(collection(firestore, collectionName));
    var data = [];
    querySnapshot.forEach((doc) => data.push(doc.data()));
    switch (collectionName) {
      case "orders":
        dispatch(setOrders(data));
        break;
      case "users":
        dispatch(setUsers(data));
        break;
      default:
        toast.error("Implementation Failed");
        break;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

// UPDATE FIRESTORE DATA - orders ONLY
export const updateFirestoreData = async (
  orderID,
  target,
  data,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true);
    const orderRef = doc(firestore, "orders", orderID);
    switch (target) {
      case "status":
        await updateDoc(orderRef, { orderStatus: data });
        toast.success(`Order Status updated to ${data}`);
        break;
      case "table":
        await updateDoc(orderRef, { orderTable: data });
        toast.success(`Table Assigned to ${data}`);
        break;
      case "payment":
        await updateDoc(orderRef, { paymentStatus: data });
        toast.success(`Payment Status updated to ${data}`);
        break;
      default:
        break;
    }
    console.log("refreshing data");
    getFirestoreData("orders", setLoading, dispatch);
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

// FILTER ORDER BY DATE
export const filterOrder = (orders, filter) => {
  const today = 24 * 60 * 60 * 1000;
  const filters = {
    All: 0,
    Today: today,
    Yesterday: 2 * today,
    PastWeek: 7 * today,
  };
  var orderFilter = [];
  orders.forEach((order) => {
    const orderDate = order.orderDate.split("/");
    const orderTime = order.orderTime.split(":");
    const orderDateTime = new Date(
      orderDate[2],
      orderDate[1] - 1,
      orderDate[0],
      orderTime[0],
      orderTime[1].slice(0, 2)
    ).getTime();
    const diff = Date.now() - orderDateTime;
    if (diff <= filters[filter]) orderFilter.push(order);
  });
  return orderFilter;
};

// ORDER STATUS FILTER
export const filterOrderStatus = (orders, status) => {
  const orderStatus = orders?.filter((order) =>
    status.includes(order.orderStatus)
  );
  return orderStatus;
};

// ORDER TYPE FILTER
export const filterOrderType = (orders, type) => {
  const orderType = orders?.filter(
    (order) => type === order.orderType //.split(" ").join("")
  );
  return orderType;
};

// FILTER ORDER BY PERSON
export const filterOrderByTable = (orders, person) => {
  const isTableOrder = person === "Self" ? true : false;
  const tableOrders = orders.filter(
    (order) => order.orderForTable === isTableOrder
  );
  return tableOrders;
};

// // PAYMENT STATUS FILTER
// export const filterPaymentStatus = (orders, status) => {
//   const paymentStatus = orders?.filter((order) =>
//     status.includes(order.paymentStatus)
//   );
//   return paymentStatus;
// };

// // PAYMENT TYPE FILTER
// export const filterPaymentType = (orders, status) => {
//   const paymentStatus = orders?.filter((order) =>
//     status.includes(order.paymentMethod)
//   );
//   return paymentStatus;
// };

// // PAYMENT METHOD FILTER
// export const filterPaymentMethod = (orders, method) => {
//   const paymentStatus = orders?.filter(
//     (order) =>
//       order.paymentStatus === "Paid" && method.includes(order.paymentMethod)
//   );
//   return paymentStatus;
// };

// // USER ROLE FILTER
// export const filterUserRole = (users, role) => {
//   const userRole = users.filter((user) => user.roles.includes(role));
//   return userRole;
// };

// // FILTER ORDERS MADE BY USER
// export const filterUserOrders = (orders, userPhone, orderType) => {
//   const filteredOrders = orders?.filter(
//     (order) => order.userPhone === userPhone && order.orderType === orderType
//   );
//   return filteredOrders;
// };

//

const onError = (error) => {
  console.log(error);
  toast.error(error?.message);
};

export const getCurrentUser = async () => {
  try {
    const userRef = doc(
      firestore,
      "users",
      localStorage.getItem("phoneNumber")
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) return userSnap.data();
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const setUserOnilne = async (user, isOnline, dispatch) => {
  try {
    if (!isOnline) dispatch(setOrders([]));
    const userRef = doc(firestore, "users", user.phone);
    await setDoc(userRef, { isOnline: { Table: isOnline } }, { merge: true });
    const status = isOnline ? "Online" : "Offline";
    const message = isOnline
      ? `${user.name} is ${status}`
      : `${user.name} went ${status}`;
    isOnline ? toast.success(`${message}`) : toast.error(`${message}`);
    getTokensByRole(ROLE.Admin).then((tokens) => {
      if (tokens.length)
        sendOrderNotification(tokens, {
          title: `${ROLE.Table} User is ${status}`,
          body: `${message}`,
          click_action: `${LINK.Admin}/users/details/${user.phone}`,
        });
    });
  } catch (error) {
    onError(error);
  }
};

export const setNotificationToken = async (userID, notificationToken) => {
  try {
    const userRef = doc(firestore, "users", userID);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const token = userSnap.data().notificationToken;
      // Check if token exists & Only update unique/new token
      if (token?.User === notificationToken) return;
      return await setDoc(
        userRef,
        {
          notificationToken: { Table: notificationToken },
        },
        { merge: true }
      );
    }
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const getTokensByRole = async (role) => {
  try {
    const userRef = collection(firestore, "users");
    const q = query(userRef, where("roles", "array-contains", role));
    const querySnapshot = await getDocs(q);
    var notificationTokens = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const notificationToken = data?.notificationToken;
      if (!notificationToken) return;
      const roleToken = notificationToken[role];
      if (!roleToken) return;
      if (role === ROLE.User || role === ROLE.Admin)
        return notificationTokens.push(roleToken);

      const isOnline = data?.isOnline;
      if (!isOnline) return;
      if (isOnline[role] && notificationToken)
        notificationTokens.push(roleToken);
    });
    return notificationTokens;
  } catch (error) {
    onError(error);
  }
};


export const notifyUserAndAdmin = (order, status, currentUser, tableOperator) => {
  const userToken = currentUser?.notificationToken?.User;
  if (userToken) {
    // Notify User
    sendOrderNotification([userToken], {
      title: `Your order #${order.orderID} is "${status}"`,
      body: `${
        !order.orderForTable
          ? `${tableOperator.name} Fulfilled your Order with Dedication, make him Happy with some Tip :)`
          : `Order for Table ${order.orderTable} ${status} successfully. Don't hesitate to ask for a Tip ;)`
      }`,
      click_action: `${LINK.User}/main/orders/${order.orderID}`,
    });

    // Notify Admin
    getTokensByRole(ROLE.Admin).then((tokens) => {
      if (tokens.length)
        sendOrderNotification(tokens, {
          title: `Order #${order.orderID} of Table ${order.orderTable} is ${status}`,
          body: `${currentUser.name}'s order #${order.orderID} for Table ${order.orderTable} is ${status} by ${tableOperator.name} | Please collect the Payment`,
          click_action: `${LINK.Admin}/orders/details/${order.orderID}`,
        });
    });
  }
}