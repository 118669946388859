import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeFilter: "Today",
  orderType: "Self",
  orderStatus: "Accepted",
  userRole: "User",
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilterData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    getFilterData: (state) => state,
  },
});

export const { setFilterData, getFilterData } = filterSlice.actions;
export default filterSlice.reducer;
