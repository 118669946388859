export const ROLE = {
  User: "User",
  DeliveryBoy: "DeliveryBoy",
  Kitchen: "Kitchen",
  Admin: "Admin",
  Table: "Table",
};

export const LINK = {
  User: 'https://dc-user.web.app',
  DeliveryBoy: 'https://dotcom-delivery.web.app',
  Kitchen: 'https://dc-kitchen.web.app',
  Admin: 'https://dotcom-admin.web.app',
};

export const ALLOWED_ROLE = ROLE.Table;

export const USER_ROLES = [
  { name: ROLE.User, icon: "user", quantity: 0 },
  { name: ROLE.DeliveryBoy, icon: "biking-mountain", quantity: 0 },
  { name: ROLE.Kitchen, icon: "hat-chef", quantity: 0 },
  { name: ROLE.Admin, icon: "user-gear", quantity: 0 },
];

export const FILTERS = ["All", "Today", "Yesterday", "PastWeek"];

export const ORDER = "DineIn"

export const ORDER_TYPE = [
  // { name: "TakeAway", icon: "hand-holding-box", quantity: 0 },
  { name: "Other", icon: "clipboard-user", quantity: 0 },
  { name: "Self", icon: "user", quantity: 0 },
];

export const PAYMENT_TYPE = [
  { name: "PayOnline", icon: "shield-check", quantity: 0 },
  { name: "PayByCash", icon: "money-bills", quantity: 0 },
];

export const STATUS = {
  Pending: {
    name: "Pending",
    color: "bg-yellow text-black",
    icon: "loading",
    quantity: 0,
  },
  Paid: {
    name: "Paid",
    color: "bg-green text-white",
    icon: "shield-check",
    quantity: 0,
  },
  Accepted: {
    name: "Accepted",
    color: "bg-blue text-white",
    icon: "text-check",
    quantity: 0,
  },
  Preparing: {
    name: "Preparing",
    color: "bg-green text-white",
    icon: "grill",
    quantity: 0,
  },
  Prepared: {
    name: "Prepared",
    color: "bg-lightgreen text-black",
    icon: "pot",
    quantity: 0,
  },
  OutForDelivery: {
    name: "OutForDelivery",
    color: "bg-p-orange text-black",
    icon: "biking-mountain",
    quantity: 0,
  },
  Delivered: {
    name: "Delivered",
    color: "bg-green text-white",
    icon: "map-marker-check",
    quantity: 0,
  },
  Completed: {
    name: "Completed",
    color: "bg-green text-white",
    icon: "check-circle",
    quantity: 0,
  },
  Cancelled: {
    name: "Cancelled",
    color: "bg-grey-dark text-white",
    icon: "cross-circle",
    quantity: 0,
  },
  Rejected: {
    name: "Rejected",
    color: "bg-red text-black",
    icon: "cross-circle",
    quantity: 0,
  },
  Returned: {
    name: "Returned",
    color: "bg-purple text-white",
    icon: "undo",
    quantity: 0,
  },
  Refunded: {
    name: "Refunded",
    color: "bg-grey-dark dark:bg-grey-light text-white dark:text-black",
    icon: "undo",
    quantity: 0,
  },
};

export const ORDER_STATUS_LIST = [
  STATUS.Pending,
  STATUS.Accepted,
  STATUS.Preparing,
  STATUS.Prepared,
  STATUS.Completed,
];

export const PAYMENT_STATUS = [STATUS.Pending, STATUS.Paid, STATUS.Refunded];
